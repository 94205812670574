import { store } from '../../../store';
import { changeReturnToJobPopup } from "../../../store/apply-action-creators";
import { Link, useParams } from "react-router-dom";

const ReturnToJobPopup = () => {
  const location = useParams()

  return (
    <div className="popup return-popup">
      <div className="popup__step">
        <div className="popup__head">
          <p className="popup__head__headline">
            Return to job details
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => store.dispatch(changeReturnToJobPopup(false))}
            className="popup__head__close"
          >
            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
          </svg>
        </div>
        <div className="popup__body">
          <p className="popup__body__text">
            Are you sure you don't want to submit an application for this job?
          </p>
        </div>
        <div className="popup__bottom end">
          <button
            className="popup__bottom__cancel button--empty"
            onClick={() => store.dispatch(changeReturnToJobPopup(false))}
          >
            Cancel
          </button>
          <Link
            to={`/job/${location.value}`}
            className={`popup__bottom__publish button--filled`}
            onClick={() => store.dispatch(changeReturnToJobPopup(false))}
          >
            confirm
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ReturnToJobPopup