import { memo } from "react";
import "./button.scss";
import { IButton } from "../../types/button-type";

const ButtonComponent = ({
  label,
  disabled = false,
  isLoading = false,
  variant = "primary",
  className='',
  ...rest
}: IButton) => {
  return (
    <button
      className={`button ${className} ${variant}-button `}
      {...rest}
    >
      {isLoading ? <div className="loading" /> : label}
    </button>
  );
};

export default memo(ButtonComponent);
