
import { createSlice } from "@reduxjs/toolkit";
import { ChatBotJobDetails } from "../../types/redux-chat-job-details";
const initialState:ChatBotJobDetails={
    jobDetails: {
		jobTitle: "",
		employer: "",
		consultancy: "",
		jobLocation: [],
		remoteLocation: false,
		salaryRangeMonth: [1000, 4000],
		salaryRangeYear: [5000, 100000],
		salaryRangeHour: [10, 300],
		negotiableSalary: false,
		jobDescription: "",
		shortJobDescription: "",
		opening: { value: "1", label: "1" },
		jobType: { value: "", label: "" },
		preferableShift: { value: "", label: "" },
		position: undefined,
		industryType: undefined,
		functionalArea: { value: "", label: "" },
		noticePeriod: { value: "", label: "" },
		status: "",
	},
	requirements: {
		skills: [],
		experienceYears: [1, 10],
		education: { value: "", label: "" },
		screeningQuestions: [],
	},
	benefits: {
		benefitsList: [],
	},
	aboutCompany: {
		aboutCompany: "",
		workflow: { value: "", label: "" },
		careerPortal: "",
		facebook: "",
		instagram: "",
		linkedin: "",
		twitter: "",
		careerPage: false,
		publicSearch: false,
	},
	savedAnswers:{},
	isLoading:false
}

const chatJobReducer = createSlice({
    initialState,
    name: "chatJob",
    reducers: {
        setChatJobDetails: (state, action) => {
            state.jobDetails = {
                ...state.jobDetails,
                ...action.payload
            };
        },
        setChatRequirements: (state, action) => {
            state.requirements = {
                ...state.requirements,
                ...action.payload
            };
        },
        setChatBenefits: (state, action) => {
			state.benefits={
				benefitsList:[...action.payload]
			}
        },
        setChatAboutCompany: (state, action) => {
            state.aboutCompany = {
                ...state.aboutCompany,
                ...action.payload
            };
        },
		setChatSavedAnswers: (state, action) => {
			state.savedAnswers = {
				...state.savedAnswers,
				...action.payload
			};
		},
		resetChatJobDetails: (state) => {
			state = initialState
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload;
		}
    }
})
export const {setChatAboutCompany,setChatBenefits,setChatJobDetails,setChatRequirements,setChatSavedAnswers,resetChatJobDetails,setIsLoading} = chatJobReducer.actions;
export default chatJobReducer.reducer;