import axios from 'axios';
import { getConfig } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { getEnv } from '@urecruits/api';

const { API_RECRUITMENT } = getEnv();

export const fetchJobSuggestions = async (message, sessionId) => {
    try {
        const response = await axios.post(
            `${API_RECRUITMENT}/api/job/suggestions`,
            JSON.stringify({ userInput: message, sessionId }),
            getConfig()
        );
        return response.data;
    } catch (error) {
        console.error("Error fetching job details:", error);
        throw new Error("Failed to fetch job details.");
    }
};

export const formatSalary = (amount) => {
    if (!amount || isNaN(amount)) return '-';
    const num = Math.round(Number(amount));
    if (num >= 1000) {
        const inK = num / 1000;
        return `${inK % 1 === 0 ? inK.toFixed(0) : inK.toFixed(1)}K`;
    }
    return num.toString();
};

export const extractJobDetails = (jobDetails:any,previousJobDetails:any) => {
    let aboutCompany:any = {
        publicSearch:true
      }
      if(jobDetails.linkedIn){
        aboutCompany.linkedIn = jobDetails.linkedIn
      }
      if(jobDetails.facebook){
        aboutCompany.facebook = jobDetails.facebook
      }
      if(jobDetails.instagram){
        aboutCompany.instagram = jobDetails.instagram
      }
      if(jobDetails.twitter){
        aboutCompany.twitter = jobDetails.twitter
      }
      if(jobDetails.aboutCompany){
        aboutCompany.aboutCompany = jobDetails.aboutCompany
      }

      
      
      const updatedJobDetails = {
        ...(jobDetails.title && { jobTitle: jobDetails.title }),
        ...(!!jobDetails.salaryMonthMin && { salaryRangeMonth: [jobDetails.salaryMonthMin, jobDetails.salaryMonthMax ?? previousJobDetails.salaryRangeMonth?.[1] ?? 0] }),
        ...(!!jobDetails.salaryMonthMax && { salaryRangeMonth: [jobDetails.salaryMonthMin ?? previousJobDetails.salaryRangeMonth?.[0] ?? 0, jobDetails.salaryMonthMax] }),
        ...(!!jobDetails.salaryYearMin && { salaryRangeYear: [jobDetails.salaryYearMin, jobDetails.salaryYearMax ?? previousJobDetails.salaryRangeYear?.[1] ?? 0] }),
        ...(!!jobDetails.salaryYearMax && { salaryRangeYear: [jobDetails.salaryYearMin ?? previousJobDetails.salaryRangeYear?.[0] ?? 0, jobDetails.salaryYearMax] }),
        ...(!!jobDetails.salaryHourMin && { salaryRangeHour: [jobDetails.salaryHourMin, jobDetails.salaryHourMax ?? previousJobDetails.salaryRangeHour?.[1] ?? 0] }),
        ...(!!jobDetails.salaryHourMax && { salaryRangeHour: [jobDetails.salaryHourMin ?? previousJobDetails.salaryRangeHour?.[0] ?? 0, jobDetails.salaryHourMax] }),
        ...(!!jobDetails.experienceMin && { experienceYears: [jobDetails.experienceMin, jobDetails.experienceMax ?? previousJobDetails.experienceYears?.[1] ?? 1] }),
        ...(!!jobDetails.experienceMax && { experienceYears: [jobDetails.experienceMin ?? previousJobDetails.experienceYears?.[0] ?? 1, jobDetails.experienceMax] }),
        ...(!!jobDetails.skills?.length && { skills: jobDetails.skills }),
        ...(jobDetails.description && { jobDescription: jobDetails.description }),
        ...(jobDetails.shortDescription && { shortJobDescription: jobDetails.shortDescription }),
        ...(jobDetails.employer && { employer: jobDetails.employer }),
        ...(jobDetails.remoteLocation && { remoteLocation: jobDetails.remoteLocation }),
        ...(jobDetails.position && { position: jobDetails.position }),
        ...(jobDetails.industry && { industryType: jobDetails.industry }),
        ...(jobDetails.education && {education:{value:jobDetails.education,label:jobDetails.education}})
      };
      let requirements:any = {}
      if(updatedJobDetails.skills?.length){
        requirements.skills = updatedJobDetails.skills
      }
      if(updatedJobDetails.experienceYears?.length){
        requirements.experienceYears = updatedJobDetails.experienceYears
      }
      if(updatedJobDetails.education?.value){
        requirements.education = updatedJobDetails.education
      }

      return {
        aboutCompany,
        benefits:{
            benefitsList:jobDetails?.benefits
        },
        jobDetails:updatedJobDetails,
        requirements
      }
}