import clock from "../image/icon/clock_ic.svg";
import JobInformation from "../components/ViewJob/JobInformation";
import defaultAvatar from "../image/icon/avatar.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import { IJob } from "../types/view-job";
import JobAction from "../components/ViewJob/JobAction";
import { decodeToken, getEnv } from "@urecruits/api";
import { store } from "../store";
import { useTypedSelector } from "../hook/useTypedSelector";
import ApplyPopup from "../components/ViewJob/Popups/ApplyPopup";
import EmptyJob from "../components/EmptyJob";
import SubscribePopup from "../components/ViewJob/Popups/SubscribePopup";
import {
  changeApplyJobStatus,
  changeSaveJobStatus,
  changeSubscribeJobStatus,
} from "../store/apply-action-creators";
import Loader from "../../../recruitments/screen/VideoMeetLoader";
import { AuthGuard, getConfig, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const dayPublishConvert = (publishDay: string): number => {
  return Math.round((Date.now() - new Date(publishDay).valueOf()) / 86400000);
};

const ViewJobScreen = () => {
  const {checkUserPermission} = useHasPermission()
  const navigate = useNavigate()
  const token: string = localStorage.getItem("token");
  const screen = useTypedSelector((state) => state.apply);
  const [jobData, setJobData] = useState<IJob>();
  const jobId = useParams().value;
  const [loader, setLoader] = useState(true);
  const [emptyJob, setEmptyJob] = useState(false);
  const { API_RECRUITMENT } = getEnv();

  useLayoutEffect(() => {
    if(jobId=='create'){
      navigate('/job/create')
    }
    jobId !== 'create' && checkUserPermission(['candidate','recruiter']) && setData().then();
  }, []);

  useEffect(() => {
    if (jobData?.subscribes?.[0]) {
      store.dispatch(changeSaveJobStatus(jobData.subscribes[0].saveJob));
      store.dispatch(
        changeSubscribeJobStatus(jobData.subscribes[0].subscribeJob)
      );
      store.dispatch(changeApplyJobStatus(jobData.subscribes[0].applyJob));
    } else {
      store.dispatch(changeSaveJobStatus(false));
      store.dispatch(changeSubscribeJobStatus(false));
      store.dispatch(changeApplyJobStatus(false));
    }
  }, [jobData]);

  const setData = async () => {
    setLoader(true)
    try{
    const userId = await decodeToken().then(
      (data) => data["https://urecruits.com/userId"]
    );
    const apiUrl = checkUserPermission('candidate') ? `${API_RECRUITMENT}/api/job/public-job/${+jobId}` : `${API_RECRUITMENT}/api/job/${+jobId}`
    const res = await axios(apiUrl, {
      params: {
        currentUserId: userId,
      },
     ...getConfig()
    })
      if (res.status === 200 && res.data) {
        setLoader(false);
        setJobData(res.data);
      } else {
        setLoader(true);
        setEmptyJob(true);
      }
  }catch(error){
    console.log(error);
    setLoader(false)
    navigate("/")
  }
  };

  const checkApplicationForm = () => {
    if (jobData?.applicationForm) {
      return jobData?.applicationForm.length === 0;
    }
  };

  return (
    <AuthGuard module={['recruiter','candidate']}>
      {!loader && (
        <section className="job-detail">
          <div className="job-detail__head">
            <div className="job-detail__head__container">
              <div className="job-detail__head__inner">
                <div className="job-detail__head__top">
                  <AuthGuard module='recruiter'>
                  <Link to={"/recruitment/jobs"}>
                      <a className="job-detail__head__breadcrumbs">
                        Jobs
                      </a>
                    </Link>
                  </AuthGuard>
                  <AuthGuard module='candidate'>
                  <Link
                      to={`/candidate/jobs`}>
                      <a className="job-detail__head__breadcrumbs">
                        Jobs
                      </a>
                    </Link>
                  </AuthGuard>
                </div>
                <div className="job-detail__head__bottom">
                  <div className="job-detail__head__logo">
                    <img
                      src={
                        jobData?.company.avatar
                          ? jobData.company.avatar
                          : defaultAvatar
                      }
                      alt={jobData?.title}
                    />
                  </div>
                  <div className="job-detail__head__right">
                    <p className="job-detail__head__headline">
                      {jobData?.title}
                    </p>
                    <ul className="job-detail__head__info">
                      <li className="job-detail__head__info__item">
                        <Link
                          to={`/candidate/company/${jobData?.company.tenantId}`}
                          className="job-detail__head__info__link">
                          {jobData?.company.name}
                        </Link>
                      </li>
                      {jobData?.locations && jobData.locations[0]?.city && (
                        <li className="job-detail__head__info__item location">
                          {jobData?.locations &&
                            jobData.locations[0]?.city &&
                            jobData.locations[0]?.city +
                              ", " +
                              jobData.locations[0]?.state}
                        </li>
                      )}
                      <li className="job-detail__head__info__item gray">
                        <img
                          src={clock}
                          alt=""
                          className="job-detail__head__info__icon"
                        />
                        {dayPublishConvert(jobData?.createdAt) === 0
                          ? "today"
                          : dayPublishConvert(jobData?.createdAt) + " days ago"}
                      </li>
                    </ul>
                    <JobAction
                    jobData={jobData}
                      color="white"
                      applicationForm={checkApplicationForm()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="job-detail__body">
            <div className="job-detail__left">
              <div className="job-detail__left__inner">
                {jobData?.description && (
                  <>
                    <p className="job-detail__headline">Job Description</p>
                    <div
                      className="job-detail__content"
                      dangerouslySetInnerHTML={{
                        __html: `${jobData.description}`,
                      }}
                    />
                  </>
                )}
                {
                  jobData?.benefits?.length && 
                  <>
                    <p className="job-detail__headline">Benefits</p>
                    <ul className="job-detail__list">
                      {jobData?.benefits?.map((item: any, index: number) => {
                        console.log({item})
                        return (
                          <li className="job-detail__list__item" key={item?.id||index}>
                            {item?.value||""}{" "}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                }
                {jobData?.skills && (
                  <>
                    <p className="job-detail__headline">Skills</p>
                    <ul className="job-detail__list">
                      {jobData?.skills?.map((item: any, index: number) => {
                        return (
                          <li className="job-detail__list__item" key={index}>
                            {item}{" "}
                          </li>
                        );
                      })}
                    </ul>
                  </>
                )}
                {jobData?.aboutCompany && (
                  <>
                    <p className="job-detail__headline">About Company</p>
                    <div
                      className="job-detail__content"
                      dangerouslySetInnerHTML={{
                        __html: `${jobData.aboutCompany}`,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="job-detail__right">
              <JobInformation
                data={jobData}
                applicationForm={checkApplicationForm()}
              />
            </div>
          </div>
        </section>
      )}
      {emptyJob && <EmptyJob />}
      {screen.applyPopup && <ApplyPopup jobData={jobData} />}
      {screen.subscribePopup && <SubscribePopup />}
      {loader && !emptyJob && <Loader/>}
    </AuthGuard>
  );
};

export default memo(ViewJobScreen);
