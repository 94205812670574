import { useState, useEffect } from "react"
import { useTypedSelector } from "../../../../hook/useTypedSelector"
import { store } from "../../../../store"
import { setChatSavedAnswers } from "../../../../store/reducers/chatJobReducer"

const DEFAULT_OPTIONS = [
  {id:1, name:"Software Engineer"},
  {id:2, name:"Data Analyst"},
  {id:3, name:"Product Manager"},
  {id:4, name:"UI/UX Designer"},
  {id:5, name:"Business Analyst"},
  {id:6, name:"Sales Manager"},
]

export const JobOptions = (props) => {
  const [selectedOption,setSeletedOption] = useState({id:0,name:""})
  const [isEditable,setIsEditable] = useState(false)
  const [newOption,setNewOption] = useState("")
  const {savedAnswers} = useTypedSelector(store=>store.chatbotJobsDetails)
  const [options,setOptions] = useState(() => {
    // Initialize from localStorage or use default options
    const savedOptions = localStorage.getItem('jobOptions')
    return savedOptions ? JSON.parse(savedOptions) : DEFAULT_OPTIONS
  })
  // Save options to localStorage whenever they change
  useEffect(() => {
    const existOptions = localStorage.getItem('jobOptions')
    if(existOptions){
      setOptions(JSON.parse(existOptions))
    }
  }, [])

  useEffect(()=>{
    const option = savedAnswers?.[`${props?.payload?.title}`]
    if(option){
      setSeletedOption(JSON.parse(option))
    }
  },[savedAnswers,props?.payload?.title])

  const handleOptionClick = (option) => {
      setIsEditable(false)
      setSeletedOption(option)
      store.dispatch(setChatSavedAnswers({[`${props?.payload?.title}`]:JSON.stringify(option)}))
      props?.actions?.sendClientMessage(`${option?.name} job`,{},{})
      props?.actions?.handleJobSuggestions(`Create a ${option?.name} job`,{},{})
  }


  // Create a computed options array that always includes "Other" at the end
  const displayOptions = [...options, {id: -1, name: "Other"}]


  return (
    <ul className="chatbot-options">{
      selectedOption.id && selectedOption.id != -1 ?
      <li className="chatbot-options__item active">{selectedOption.name}</li>
      :
      <>
      {
        displayOptions?.map((option,index)=>{
          return (
            <li className={`chatbot-options__item ${!isEditable ? 'hover' : ''}`} key={index} onClick={()=>{
              if(option.name == "Other"){
                setIsEditable(true)
                setSeletedOption(option)
              }else{
                handleOptionClick(option)
              }
            }}> 
              {isEditable && selectedOption.id == option.id ? 
              <div className="chatbot-option-input">
                <input value={newOption} onChange={(e)=>{setNewOption(e.target.value);}} placeholder="Enter your job title"/>
                <p onClick={()=>{
                  // Add new option before the "Other" option
                  if(newOption){
                    const newId = Math.max(...options.map(opt => opt.id)) + 1
                    setOptions([...options, {id: newId, name: newOption}])
                    setSeletedOption({id:0,name:""})
                    setNewOption("")
                  }
                  setIsEditable(false)
                }}>Add</p>
              </div> :
              <p className="">{option?.name}</p>}
            </li>
          )
        })
      }
      </>
      }
      </ul>
  )
}
