export const FunctionalAreaOption = [
    { id:1, value: "Executive Management", label: "Executive Management" },
    { id:2, value: "Human Resources (HR)", label: "Human Resources (HR)" },
    { id:3, value: "Finance and Accounting", label: "Finance and Accounting" },
    { id:4, value: "Human resources", label: "Human resources" },
    { id:5, value: "Sales and Marketing", label: "Sales and Marketing" },
    { id:6, value: "Information Technology (IT)", label: "Information Technology (IT)" },
    { id:7, value: "Research and Development (R&D)", label: "Research and Development (R&D)" },
    { id:8, value: "Customer Service", label: "Customer Service" },
    { id:9, value: "Legal and Compliance", label: "Legal and Compliance" },
    { id:10, value: "Procurement and Purchasing", label: "Procurement and Purchasing" },
    { id:11, value: "Public Relations (PR)", label: "Public Relations (PR)" },
    { id:12, value: "Quality Assurance (QA)", label: "Quality Assurance (QA)" },
    { id:13, value: "Facilities Management", label: "Facilities Management" },
    { id:14, value: "Strategic Planning", label: "Strategic Planning" },
    { id:15, value: "Training and Development", label: "Training and Development" },
    { id:16, value: "Internal Audit", label: "Internal Audit" },
    { id:17, value: "Investor Relations", label: "Investor Relations" },
  ];
  export const JobTypeOption = [
    {id:1, value: "Full-Time Employees", label: "Full-Time Employees" },
    {id:2, value: "Part-Time Employees", label: "Part-Time Employees" },
    {id:3, value: "Temporary Employees", label: "Temporary Employees" },
    {id:4, value: "Seasonal Employees", label: "Seasonal Employees" },
    {id:5, value: "Independent Contractors", label: "Independent Contractors" },
    {id:6, value: "Freelancers", label: "Freelancers" },
    {id:7, value: "Temporary workers", label: "Temporary workers" },
    {id:8, value: "Consultants", label: "Consultants" },
  ];
  export const PreferableShiftOption = [
    {id:1, value: "General Shift", label: "General Shift" },
    {id:2, value: "Morning Shift", label: "Morning Shift" },
    {id:3, value: "Afternoon Shift", label: "Afternoon Shift" },
    {id:4, value: "Evening Shift", label: "Evening Shift" },
    {id:5, value: "Night Shift", label: "Night Shift" },
    {id:6, value: "Flexible Shift", label: "Flexible Shift" },
  ];
  export const NoticePeriodOption = [
    {id:1, value: "Immediately", label: "Immediately" },
    {id:2, value: "Within 15 days", label: "Within 15 days" },
    {id:3, value: "1 Month", label: "1 Month" },
    {id:4, value: "2 Months", label: "2 Month" },
    {id:5, value: "3 Months", label: "3 Month" },
    {id:6, value: "5 Months", label: "4 Month" },
  ];
  