import { ActionEnums, ScreenEnums } from "../redux-enums";
import { IAction, IScreen } from "../../types/redux-types";

export const screenReducer = (state: IScreen = initialState, action: IAction): IScreen => {
	switch (action.type) {
		case ActionEnums.CHANGE_JOB_DETAILS:
			return { ...{}, ...state, jobDetails: action.payload };
		case ActionEnums.CHANGE_REQUIREMENTS:
			return { ...{}, ...state, requirements: action.payload };
		case ActionEnums.CHANGE_BENEFITS:
			return { ...{}, ...state, benefits: action.payload };
		case ActionEnums.CHANGE_ABOUT_COMPANY:
			return { ...{}, ...state, aboutCompany: action.payload };
		case ActionEnums.CHANGE_CURRENT_SCREEN:
			return { ...{}, ...state, currentScreen: action.payload };
		case ActionEnums.JOB_DETAILS_VALIDATE:
			return { ...{}, ...state, jobDetailsValidate: action.payload };
		case ActionEnums.REQUIREMENTS_VALIDATE:
			return { ...{}, ...state, requirementsValidate: action.payload };
		case ActionEnums.CHANGE_APPLICATION_FORM:
			return { ...{}, ...state, applicationForm: state.applicationForm.map(x => x.name === action.payload.name ? action.payload : x) };
		case ActionEnums.SET_APPLICATION_FORM:
			return { ...{}, ...state, applicationForm: action.payload };
		case ActionEnums.CHANGE_JOB_BOARDS:
			return { ...{}, ...state, jobBoards: action.payload };
		case ActionEnums.CHANGE_ACTION_STATE:
			return { ...{}, ...state, actionState: action.payload };
		case ActionEnums.CHANGE_DRAFT_POPUP:
			return { ...{}, ...state, draftPopup: action.payload };
		case ActionEnums.CHANGE_PUBLISH_POPUP:
			return { ...{}, ...state, publishPopup: action.payload };
		case ActionEnums.CHANGE_SAVED_POPUP:
			return { ...{}, ...state, savedPopup: action.payload };
		case ActionEnums.CHANGE_SAVE_TEMPLATE_POPUP:
			return { ...{}, ...state, saveTemplatePopup: action.payload };
		case ActionEnums.CHANGE_APPROVAL_POPUP:
			return { ...{}, ...state, approvalPopup: action.payload };
		case ActionEnums.CHANGE_CANCEL_POPUP:
			return { ...{}, ...state, cancelPopup: action.payload };
		case ActionEnums.ATTACH_APPLICATION_FORM:
			return { ...{}, ...state, attachApplicationFom: action.payload };
		case ActionEnums.CHANGE_WORKFLOWS_LIST:
			return { ...{}, ...state, workflowList: action.payload };
		case "reset_screen_details":
			return { ...initialState, actionState: state.actionState };
		default:
			return state;
	}
};

export const initialState: IScreen = {
	workflowList: [],
	actionState: "",
	jobDetails: {
		jobTitle: "",
		employer: "",
		consultancy: "",
		jobLocation: [],
		remoteLocation: false,
		salaryRangeMonth: [1000, 4000],
		salaryRangeYear: [5000, 100000],
		salaryRangeHour: [10, 300],
		negotiableSalary: false,
		jobDescription: "",
		shortJobDescription: "",
		opening: { value: "1", label: "1" },
		jobType: { value: "", label: "" },
		preferableShift: { value: "", label: "" },
		position: undefined,
		industryType: undefined,
		functionalArea: { value: "", label: "" },
		noticePeriod: { value: "", label: "" },
		status: "",
	},
	requirements: {
		skills: [],
		experienceYears: [1, 10],
		education: { value: "", label: "" },
		screeningQuestions: [],
	},
	benefits: {
		benefitsList: [],
	},
	aboutCompany: {
		aboutCompany: "",
		workflow: { value: "", label: "" },
		careerPortal: "",
		facebook: "",
		instagram: "",
		linkedin: "",
		twitter: "",
		careerPage: false,
		publicSearch: false,
	},
	jobBoards: {
		integrations: [
			{ name: "uRecruits", id: 13, uniqueIntegrationId: "uRecruits08122022" },
			// { name: "Facebook Jobs", id: 3, uniqueIntegrationId: "facebookJobs08122022" },
			{ name: "Google Jobs", id: 14, uniqueIntegrationId: "googleJobs08122022" },
			// { name: "Dice", id: 7, uniqueIntegrationId: "freeDice08122022" },
			// { name: "Linkedln", id: 2, uniqueIntegrationId: "freeLinkedln08122022" },
		],
	},
	applicationForm: [],
	currentScreen: ScreenEnums.JOB_DETAILS,
	draftPopup: false,
	approvalPopup: false,
	publishPopup: false,
	cancelPopup: false,
	saveTemplatePopup: false,
	savedPopup: false,
	jobDetailsValidate: false,
	requirementsValidate: false,
	attachApplicationFom: false,
};