import { useEffect, useState } from "react";
import { store } from "../../store";
import {
	changeApprovalPopup,
	changeCancelPopup,
	changeDraftPopup,
	changePublishPopup,
	requirementsValidate,
} from "../../store/action-creators";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const draftIcon = require("../../image/icon/red_edit_ic.svg");
const messageIcon = require("../../image/icon/green_message_ic.svg");


const JobHead = () => {
	const jobDetailsValidateValue = useTypedSelector(state => state.screen.jobDetailsValidate);
	const job = useTypedSelector(state => state.screen.jobDetails);

	const requirementsValidateValue = useTypedSelector(state => state.screen.requirementsValidate);

	const actionState = useTypedSelector(state => state.screen.actionState);

	const {skills, education} = useTypedSelector(state => state.screen.requirements);

	const [actionToggle, setActionToggle] = useState(false);

	const onCancel = () => {
		store.dispatch(changeCancelPopup(true));
	};

	const onDraft = () => {
		store.dispatch(changeDraftPopup(true));
	};

	const onApproval = () => {
		if (stepsValidateStatus()) {
			store.dispatch(changeApprovalPopup(true));
		}
	};

	const onPublish = () => {
		if (stepsValidateStatus()) {
			store.dispatch(changePublishPopup(true));
		}
	};


	const stepsValidateStatus = () => {
		return jobDetailsValidateValue && requirementsValidateValue;
	};

	useEffect(() => {
		skills.length > 0 && education.value && (
			store.dispatch(requirementsValidate(true))
		);

	}, [skills, education]);

	return (
		<div className="head">
			<div className="head__left">
				<h2>
					{actionState}
				</h2>
			</div>
			<div className="head__right">
				<div className="head__right__desktop">
					<button
						className="head__button head__cancel"
						onClick={onCancel}
					>
						Cancel
					</button>
					{job?.status !=='publish' &&
					<>
					<button
						className="head__button head__draft"
						onClick={onDraft}
						>
						<img src={draftIcon} alt="draft icon"/>
						Send to Draft
					</button>
					<ShowToolTipComponent text='Send the job for approval to any of your team members.'>
						<button
						className={`head__button head__approval ${!stepsValidateStatus() ? "disable" : ""}`}
						onClick={onApproval}
						>
						<img src={messageIcon} alt="message icon"/>
						Send for Approval
					</button>
					</ShowToolTipComponent>
					</>
					}
					<button
						className={`head__publish button--filled ${!stepsValidateStatus() ? "button--filled-disable" : ""}`}
						onClick={onPublish}
					>
						{actionState=='Edit Job' ?( job.status=='draft' ? "Publish":"Save") :"Publish"}
					</button>
				</div>
				<div className="head__right__mobile">
					<button
						className="head__prev"
						onClick={onCancel}
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
						     className="head__prev__icon">
							<path d="M11.2008 1.59961L4.80078 7.99961L11.2008 14.3996" stroke="#999EA5" strokeWidth="1.5"
							      strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</button>
					<button
						className={`head__mobile-publish button--filled ${!stepsValidateStatus() ? "button--filled-disable" : ""}`}
						onClick={onPublish}
					>
						{actionState=='Edit Job' ?( job.status=='draft' ? "Publish":"Save") :"Publish"}
					</button>
					<div className="head__action">
						<button
							className={`head__action__button`}
							onClick={() => setActionToggle((prev) => !prev)}
						>
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13 3V10H19L11 21V14H5L13 3Z" stroke="#099C73" strokeWidth="1.5" strokeLinecap="round"
								      strokeLinejoin="round"/>
							</svg>
						</button>
						{job?.status !=='publish'&& <div className={actionToggle ? "head__action__list active" : "head__action__list"}>
							<button className="head__action__item" onClick={onDraft}>
								<img src={draftIcon} alt="draft icon" className="head__action__img"/>
								<p className="head__action__text">Send to Draft</p>
							</button>
							<button className={`head__action__item ${!stepsValidateStatus() ? "disable" : ""}`} onClick={onApproval}>
								<img src={messageIcon} alt="approval icon" className="head__action__img"/>
								<p className="head__action__text">Send for Approval</p>
							</button>
						</div>}
					</div>
				</div>
			</div>
		</div>
	);
};

export default JobHead;