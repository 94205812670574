import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from './CutomComponents/BotAvatar';
import IConfig from 'react-chatbot-kit/build/src/interfaces/IConfig';
import { JobOptions } from './Widgets/JobOptions';
import DynamicOptions from './CustomComponents/DynamicOptions';
import Buttons from './Widgets/Buttons';
import { store } from '../../../store';
import { resetChatJobDetails, setChatSavedAnswers } from '../../../store/reducers/chatJobReducer';

const SESSION_EXPIRY_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
const SESSION_KEY = 'chatbot_session';

interface SessionData {
  id: string;
  expiryTime: number;
}

function isSessionValid(sessionData: SessionData | null): boolean {
  if (!sessionData) return false;
  return Date.now() < sessionData.expiryTime;
}

function getSessionId() {
  try {
    const sessionDataStr = sessionStorage.getItem(SESSION_KEY);
    const sessionData: SessionData | null = sessionDataStr ? JSON.parse(sessionDataStr) : null;

    if (isSessionValid(sessionData)) {
      // Update expiry time
      const updatedSessionData: SessionData = {
        id: sessionData!.id,
        expiryTime: Date.now() + SESSION_EXPIRY_TIME
      };
      sessionStorage.setItem(SESSION_KEY, JSON.stringify(updatedSessionData));
      return sessionData!.id;
    }

    // Create new session if none exists or current one is expired
    const newSessionData: SessionData = {
      id: crypto.randomUUID(),
      expiryTime: Date.now() + SESSION_EXPIRY_TIME
    };
    sessionStorage.setItem(SESSION_KEY, JSON.stringify(newSessionData));
    return newSessionData.id;
  } catch (error) {
    console.error('Error managing session:', error);
    return crypto.randomUUID();
  }
}

function clearExpiredSession() {
  const sessionDataStr = sessionStorage.getItem(SESSION_KEY);
  if (!sessionDataStr) return;

  const sessionData: SessionData = JSON.parse(sessionDataStr);
  if (!isSessionValid(sessionData)) {
    sessionStorage.removeItem(SESSION_KEY);
    // Clear other related session data
    store.dispatch(resetChatJobDetails())
  }
}

// Check for expired session on load
clearExpiredSession();

const config: IConfig = {
  initialMessages: [
    createChatBotMessage(`Welcome to uRecruits, let's create a job posting for you!`, {
    }),
    createChatBotMessage(`Please select the job title you want to create:`, {
      widget: "select-job",
      payload: {
        setSavedAnswers: (data) => {
          store.dispatch(setChatSavedAnswers(data))
        },
        title: "select-job",
      },
      delay: 1000,
    }),
  ],
  botName: "Your Job Agent",
  customComponents: {
    botAvatar: BotAvatar,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#099C73",
    },
    chatButton: {
      backgroundColor: "#099C73",
    },
  },
  state: {
    sessionId: getSessionId(),
  },
  widgets: [
    {
      widgetName: "select-job",
      widgetFunc: (props: any) => React.createElement(JobOptions, props),
      mapStateToProps: [],
      props: {}
    }, {
      widgetName: "dynamic-options",
      widgetFunc: (props: any) => React.createElement(DynamicOptions, props),
      mapStateToProps: [],
      props: {}
    },
    {
      widgetName: "buttons",
      widgetFunc: (props: any) => React.createElement(Buttons, props),
      mapStateToProps: [],
      props: {}
    }
  ],
};

export default config;
