import { default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import { memo, useEffect, useState } from "react";
import rangeValidate from "../../../hook/rangeValidate";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { store } from "../../../store";
import {
  changeCurrenScreen,
  changeJobDetails,
  jobDetailsValidate,
} from "../../../store/action-creators";
import { ScreenEnums } from "../../../store/redux-enums";
import AsyncSelect from "react-select/async";
import RichTextEditor from "../../RichTextEditor";
import {
  selectSearchFuncIndustry,
  selectSearchFuncPositionLimit,
} from "../../../hook/selectSearchFunc";
import {
  FilterWithLimitComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { selectSearchFuncLocationLimit } from "@urecruits/api";
import SalaryRangeComponent from './SalaryRange';
import { JobTypeOption, PreferableShiftOption, NoticePeriodOption, FunctionalAreaOption } from "../../../util/constants";
const SALARY_YEAR_MIN = 1;
const SALARY_YEAR_MAX = 1000000;

const SALARY_MONTH_MIN = 1;
const SALARY_MONTH_MAX = 200000;
const SALARY_HOUR_MIN = 1;
const SALARY_HOUR_MAX = 500;

const VALIDATION_MESSAGES = {
  range: " No. of opening must be between 0 and 50.",
  required: "Required field",
  floatValue: "Only numbers (integers) are allowed. Example: 3, 42, 100",
  allRequired: "Please fill in the required fields",
  inValidateValue: "Please enter validate value",
};

const JobDetails = () => {
  const actionState = useTypedSelector((state) => state.screen.actionState);
  const popupStatus = useTypedSelector((state) => state.screen.draftPopup);
  const initData = useTypedSelector((state) => state.screen.jobDetails);

  //options list
  const [jobTitle, setJobTitle] = useState(initData.jobTitle);
  const [employer, setEmployer] = useState(initData.employer);
  const [consultancy, setConsultancy] = useState(initData.consultancy);
  const [jobLocation, setJobLocation] = useState(initData.jobLocation);

  const [jobDescription, setJobDescription] = useState(initData.jobDescription);
  const [shortJobDescription, setShortJobDescription] = useState(
    initData.shortJobDescription
  );
  const [openings, setOpening] = useState(initData.opening);
  const [jobType, setJobType] = useState(initData.jobType);
  const [preferableShift, setPreferableShift] = useState(
    initData.preferableShift
  );
  const [position, setPosition] = useState(initData.position);
  const [industryType, setIndustryType] = useState(initData.industryType);
  const [functionalArea, setFunctionalArea] = useState(initData.functionalArea);
  const [noticePeriod, setNoticePeriod] = useState(initData.noticePeriod);

  //checkbox data
  const [consultancyCheckbox, setConsultancyCheckbox] =
    useState<boolean>(false);
  const [remoteLocationCheckbox, setRemoteLocationCheckbox] = useState(
    initData.remoteLocation
  );
  const [negotiableCheckbox, setNegotiableCheckbox] = useState(
    initData.negotiableSalary
  );

  const [selectedSalaryRange, setSelectedSalaryRange] = useState('monthly')
  const [salaryHourlyRange, setSalaryHourlyRange] = useState({
    min: SALARY_HOUR_MIN,
    max: SALARY_HOUR_MAX,
    value: initData.salaryRangeHour[1],
    prevData: initData.salaryRangeHour[0],
    minError: false,
    maxError: false
  })

  const [salaryMonthlyRange, setSalaryMonthlyRange] = useState({
    min: SALARY_MONTH_MIN,
    max: SALARY_MONTH_MAX,
    value: initData.salaryRangeMonth[1],
    prevData: initData.salaryRangeMonth[0],
    minError: false,
    maxError: false
  })
  const [salaryYearlyRange, setSalaryYearlyRange] = useState({
    min: SALARY_YEAR_MIN,
    max: SALARY_YEAR_MAX,
    value: initData.salaryRangeYear[1],
    prevData: initData.salaryRangeYear[0],
    minError: false,
    maxError: false
  })

  const [jobTitleValidate, setJobTitleValidate] = useState(false);
  const [jobLocationValidate, setJobLocationValidate] = useState(false);
  const [employerValidate, setEmployerValidate] = useState(false);
  const [jobDescriptionValidate, setJobDescriptionValidate] = useState(false);
  const [shortJobDescriptionValidate, setShortJobDescriptionValidate] =
    useState(false);
  const [jobTypeValidate, setJobTypeValidate] = useState(false);
  const [preferableShiftValidate, setPreferableShiftValidate] = useState(false);
  const [positionValidate, setPositionValidate] = useState(false);
  const [industryTypeValidate, setIndustryTypeValidate] = useState(false);
  const [functionalAreaValidate, setFunctionalAreaValidate] = useState(false);
  const [openingsValidate, setOpeningsValidate] = useState(false);
  const [openingsRangeValidation, setOpeningsRangeValidation] = useState(null);
  const [noticePeriodValidate, setNoticePeriodValidate] = useState(false);
  const [consultancyValidate, setConsultancyValidate] = useState(false);

  const [stepValidate, setStepValidate] = useState(null);

  const defaultValues = () => {
    setJobTitle(initData.jobTitle);
    setEmployer(initData.employer);
    setConsultancy(initData.consultancy);
    setJobLocation(initData.jobLocation);
    setJobDescription(initData.jobDescription);
    setOpening(initData.opening);
    setJobType(initData.jobType);
    setPreferableShift(initData.preferableShift);
    setSalaryHourlyRange({
      min: SALARY_HOUR_MIN,
      max: SALARY_HOUR_MAX,
      value: initData.salaryRangeHour[1],
      prevData: initData.salaryRangeHour[0],
      minError: false,
      maxError: false
    })
    setSalaryMonthlyRange({
      min: SALARY_MONTH_MIN,
      max: SALARY_MONTH_MAX,
      value: initData.salaryRangeMonth[1],
      prevData: initData.salaryRangeMonth[0],
      minError: false,
      maxError: false
    })
    setSalaryYearlyRange({
      min: SALARY_YEAR_MIN,
      max: SALARY_YEAR_MAX,
      value: initData.salaryRangeYear[1],
      prevData: initData.salaryRangeYear[0],
      minError: false,
      maxError: false
    })
    setPosition(initData.position);
    setIndustryType(initData.industryType);
    setFunctionalArea(initData.functionalArea);
    setNoticePeriod(initData.noticePeriod);
    setRemoteLocationCheckbox(initData.remoteLocation);
    setNegotiableCheckbox(initData.negotiableSalary);
    setShortJobDescription(initData.shortJobDescription);
  };

  useEffect(() => {
    defaultValues();
  }, [initData]);
  
  useEffect(() => {
    if (actionState === "Edit Job") {
      setDataToRedux();
    }
  }, [
    jobTitle,
    employer,
    consultancy,
    jobLocation,
    remoteLocationCheckbox,
    salaryMonthlyRange.value,
    salaryYearlyRange.value,
    salaryHourlyRange.value,
    negotiableCheckbox,
    jobDescription,
    openings,
    preferableShift,
    industryType,
    functionalArea,
    noticePeriod,
    shortJobDescription,
  ]);

  const setDataToRedux = () => {
    store.dispatch(
      changeJobDetails({
        jobTitle: jobTitle,
        employer: employer,
        consultancy: consultancy,
        jobLocation: jobLocation,
        remoteLocation: remoteLocationCheckbox,
        salaryRangeMonth: [salaryMonthlyRange.prevData, salaryMonthlyRange.value],
        salaryRangeYear: [salaryYearlyRange.prevData, salaryYearlyRange.value],
        salaryRangeHour: [salaryHourlyRange.prevData, salaryHourlyRange.value],
        negotiableSalary: negotiableCheckbox,
        jobDescription: jobDescription,
        opening: openings,
        jobType: jobType,
        preferableShift: preferableShift,
        position: position,
        industryType: industryType,
        functionalArea: functionalArea,
        noticePeriod: noticePeriod,
        shortJobDescription: shortJobDescription,
        status: initData.status
      })
    );
  };

  //TODO: need to research best validate decision
  const moveToNextStep = () => {
    jobTitle.length === 0 ||
      jobLocation.length === 0 ||
      employer.length === 0 ||
      jobDescription.length === 0 ||
      shortJobDescription.length === 0 ||
      jobType?.value?.length === 0 ||
      preferableShift?.value?.length === 0 ||
      position?.value?.length === 0 ||
      industryType?.value?.length === 0 ||
      functionalArea?.value?.length === 0 ||
      salaryYearlyRange.minError ||
      salaryYearlyRange.maxError ||
      salaryHourlyRange.minError ||
      salaryHourlyRange.maxError ||
      salaryMonthlyRange.minError ||
      salaryMonthlyRange.maxError
      ? setStepValidate(VALIDATION_MESSAGES.allRequired)
      : openings.value.includes(".")
        ? setStepValidate(VALIDATION_MESSAGES.inValidateValue)
        : setStepValidate(null);
    //error style trigger
    jobTitle.length === 0 && setJobTitleValidate(true);
    jobLocation.length === 0 && setJobLocationValidate(true);
    employer.length === 0 && setEmployerValidate(true);
    jobDescription.length === 0 && setJobDescriptionValidate(true);
    shortJobDescription.length === 0 && setShortJobDescriptionValidate(true);
    !jobType.value && setJobTypeValidate(true);
    !openings.value && setOpeningsValidate(true);
    !noticePeriod.value && setNoticePeriodValidate(true);
    !preferableShift.value && setPreferableShiftValidate(true);
    !position?.value && setPositionValidate(true);
    !industryType?.value && setIndustryTypeValidate(true);
    !functionalArea.value && setFunctionalAreaValidate(true);
    consultancy.length === 0 &&
      consultancyCheckbox &&
      setConsultancyValidate(true);
    //check validate
    if (
      (consultancy.length !== 0 || !consultancyCheckbox) &&
      jobTitle.length !== 0 &&
      (jobLocation.length !== 0 || remoteLocationCheckbox) &&
      employer.length !== 0 &&
      jobType.value &&
      openings.value &&
      !openingsRangeValidation &&
      noticePeriod.value &&
      preferableShift.value &&
      position.value &&
      industryType.value &&
      functionalArea.value &&
      jobDescription.length !== 0 &&
      shortJobDescription.length !== 0 &&
      !salaryYearlyRange.minError &&
      !salaryYearlyRange.maxError &&
      !salaryHourlyRange.minError &&
      !salaryHourlyRange.maxError &&
      !salaryMonthlyRange.minError &&
      !salaryMonthlyRange.maxError
    ) {
      setDataToRedux();
      store.dispatch(jobDetailsValidate(true));
      store.dispatch(changeCurrenScreen(ScreenEnums.REQUIREMENTS));
    }
  };

  //set actually data to redux
  useEffect(() => {
    if (popupStatus) {
      setDataToRedux();
    }
  }, [popupStatus]);

  //publish/approval button validate logic
  useEffect(() => {
    if (
      !!jobTitle &&
      !!jobLocation.length &&
      !!jobType.value &&
      !!employer &&
      !!preferableShift.value &&
      !!position?.value &&
      !!position?.value &&
      !!openings.value &&
      !!noticePeriod.value &&
      !!functionalArea?.value &&
      !!shortJobDescription &&
      !!jobDescription
    ) {
      store.dispatch(jobDetailsValidate(true));
    } else {
      store.dispatch(jobDetailsValidate(false));
    }
  }, [
    jobTitle,
    employer,
    jobType,
    preferableShift,
    position,
    industryType,
    functionalArea,
    jobLocation,
    jobDescription,
    shortJobDescription,
  ]);

  //trigger range validate when use <CustomRange/> component
  useEffect(() => {
    let min = false, max = false
    if (selectedSalaryRange === 'yearly') {
      (rangeValidate(
        salaryYearlyRange.prevData,
        [salaryYearlyRange.prevData, salaryYearlyRange.value],
        SALARY_YEAR_MIN,
        SALARY_YEAR_MAX,
        "min"
      )) ? min = true : min = false
      rangeValidate(
        salaryYearlyRange.value,
        [salaryYearlyRange.prevData, salaryYearlyRange.value],
        SALARY_YEAR_MIN,
        SALARY_YEAR_MAX,
        "max"
      ) ? max = true : max = false
      setSalaryYearlyRange(prev => ({ ...prev, minError: min, maxError: max }))
    }
    else if (selectedSalaryRange === 'monthly') {
      rangeValidate(
        salaryMonthlyRange.prevData,
        [salaryMonthlyRange.prevData, salaryMonthlyRange.value],
        SALARY_MONTH_MIN,
        SALARY_MONTH_MAX,
        "min"
      ) ? min = true : min = false
      rangeValidate(
        salaryMonthlyRange.value,
        [salaryMonthlyRange.prevData, salaryMonthlyRange.value],
        SALARY_MONTH_MIN,
        SALARY_MONTH_MAX,
        "max"
      ) ? max = true : max = false
      setSalaryMonthlyRange(prev => ({ ...prev, minError: min, maxError: max }))
    }
    else if (selectedSalaryRange === 'hourly') {
      rangeValidate(
        salaryHourlyRange.prevData,
        [salaryHourlyRange.prevData, salaryHourlyRange.value],
        SALARY_HOUR_MIN,
        SALARY_HOUR_MAX,
        "min"
      ) ? min = true : min = false
      rangeValidate(
        salaryHourlyRange.value,
        [salaryHourlyRange.prevData, salaryHourlyRange.value],
        SALARY_HOUR_MIN,
        SALARY_HOUR_MAX,
        "max"
      ) ? max = true : max = false
      setSalaryHourlyRange(prev => ({ ...prev, minError: min, maxError: max }))
    }
  }, [selectedSalaryRange,
    salaryYearlyRange.value, 
    salaryMonthlyRange.value, 
    salaryHourlyRange.value,
    salaryYearlyRange.prevData,
    salaryMonthlyRange.prevData,
    salaryHourlyRange.prevData
  ]);



  useEffect(() => {
    if (!openings.value) {
      setOpeningsRangeValidation(VALIDATION_MESSAGES.required);
    } else if (
      (openings.value && +openings.value < 1) ||
      +openings.value > 50
    ) {
      setOpeningsRangeValidation(VALIDATION_MESSAGES.range);
    } else if (openings.value && openings.value.includes(".")) {
      setOpeningsRangeValidation(VALIDATION_MESSAGES.floatValue);
    } else {
      setOpeningsRangeValidation(null);
    }
  }, [openingsRangeValidation, openings, VALIDATION_MESSAGES]);

  return (
    // @ts-ignore
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">01. Job Details</p>
      </div>
      <div className="step__body">
        <div className="step__row">
          <div className={jobTitleValidate ? "step__item error" : "step__item"}>
            <p className="step__item__label">
              Job Title<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="jobTitle"
              id="jobTitle"
              placeholder="Enter job title"
              value={jobTitle}
              onChange={(e) => {
                e.target.value.length === 0
                  ? setJobTitleValidate(true)
                  : setJobTitleValidate(false);
                setJobTitle(e.target.value);
              }}
            />
            <p className="error-message">Required field</p>
          </div>
          <div className={employerValidate ? "step__item error" : "step__item"}>
            <p className="step__item__label">
              Employer<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="employer"
              id="employer"
              value={employer}
              onChange={(e) => {
                e.target.value.length === 0
                  ? setEmployerValidate(true)
                  : setEmployerValidate(false);
                setEmployer(e.target.value);
              }}
              placeholder="Enter employer"
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div className="step__item">
            <div className="custom-checkbox bottom">
              <input
                type="checkbox"
                className="custom-checkbox__input"
                id="consultancy"
                name="consultancy"
                checked={consultancyCheckbox}
                onChange={(e) => {
                  setConsultancyCheckbox(!consultancyCheckbox);
                  setConsultancyValidate(false);
                }}
              />
              <label htmlFor="consultancy" className="custom-checkbox__label">
                <div className="custom-checkbox__item">
                  <span />
                </div>
                <p className="custom-checkbox__text">Consultancy</p>
              </label>
            </div>
          </div>
          <div
            className={`${
              consultancyValidate ? "step__item error" : "step__item"
            }`}
          >
            <p className="step__item__label">
              Consultancy
              {consultancyCheckbox && <span> *</span>}
            </p>
            <input
              type="text"
              className={`step__item__input ${
                !consultancyCheckbox ? "disable" : ""
              }`}
              placeholder={`${
                !consultancyCheckbox
                  ? "Not indicated"
                  : "Enter consultancy name"
              }`}
              disabled={!consultancyCheckbox}
              name="consultancy"
              id="consultancy"
              value={consultancy}
              onChange={(e) => {
                setConsultancy(e.target.value);
                e.target.value.length !== 0 && setConsultancyValidate(false);
              }}
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div
            className={
              jobLocationValidate ? "step__item long  error" : "step__item long"
            }
          >
            <p className="step__item__label">
              Job Location
              {!remoteLocationCheckbox && <span> *</span>}
            </p>
            <FilterWithLimitComponent
              filter={{
                value: jobLocation,
                onChangeValue: (option) => {
                  setJobLocation(option);
                  option.length === 0 && !remoteLocationCheckbox
                    ? setJobLocationValidate(true)
                    : setJobLocationValidate(false);
                },
              }}
              placeHolder="Select Location"
              setLimitSearchfunction={selectSearchFuncLocationLimit}
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div className="step__item">
            <div className="custom-checkbox salary">
              <input
                type="checkbox"
                className="custom-checkbox__input"
                id="remote"
                name="remote"
                checked={remoteLocationCheckbox}
                onChange={(e) =>
                  setRemoteLocationCheckbox(!remoteLocationCheckbox)
                }
              />
              <label htmlFor="remote" className="custom-checkbox__label">
                <div className="custom-checkbox__item">
                  <span></span>
                </div>
                <p className="custom-checkbox__text">
                  Remote location available
                </p>
              </label>
            </div>
          </div>
        </div>
        <div className="step__row">
          <div className="step__item  range-inner">
            <p className="step__item__label gray">
              Salary Range<span> (per year)</span>
            </p>
            <ul className="step__range__list">
              <li className={`step__range__list__item ${selectedSalaryRange === 'monthly'? 'active':''}`} onClick={()=>setSelectedSalaryRange("monthly")}>Monthly</li>
              <li className={`step__range__list__item ${selectedSalaryRange === 'yearly'? 'active':''}`} onClick={()=>setSelectedSalaryRange("yearly")}>Yearly</li>
              <li className={`step__range__list__item ${selectedSalaryRange === 'hourly'? 'active':''}`} onClick={()=>setSelectedSalaryRange("hourly")}>Hourly</li>
            </ul>
            <div className="step__range">
              {
                selectedSalaryRange === 'yearly' 
                ?
                <SalaryRangeComponent 
                salary={salaryYearlyRange}
                setSalary={setSalaryYearlyRange}
                />
                : selectedSalaryRange === 'monthly'
                ?
                <SalaryRangeComponent
                salary={salaryMonthlyRange}
                setSalary={setSalaryMonthlyRange}
                />
                :
                <SalaryRangeComponent
                salary={salaryHourlyRange}
                setSalary={setSalaryHourlyRange}
                />
              }
            </div>
          </div>
        </div>
        <div className="step__item">
          <div className="custom-checkbox negotiable">
            <input
              type="checkbox"
              className="custom-checkbox__input"
              id="negotiable"
              name="negotiable"
              checked={negotiableCheckbox}
              onChange={(e) => setNegotiableCheckbox(!negotiableCheckbox)}
            />
            <label htmlFor="negotiable" className="custom-checkbox__label">
              <div className="custom-checkbox__item">
                <span></span>
              </div>
              <p className="custom-checkbox__text">Negotiable</p>
            </label>
          </div>
        </div>
        <div className="step__row">
          <div
            className={
              jobDescriptionValidate
                ? "step__item long  error"
                : "step__item long "
            }
          >
            <p className="step__item__label">
              Job Description<span> *</span>
            </p>
            <RichTextEditor
              value={jobDescription}
              setValue={setJobDescription}
              placeholder="Write job description"
              validateFlag={true}
              setValidate={setJobDescriptionValidate}
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div
            className={
              shortJobDescriptionValidate
                ? "step__item long  error"
                : "step__item long "
            }
          >
            <p className="step__item__label">
              Short Job Description<span> *</span>
            </p>
            <input
              type="text"
              className="step__item__input"
              name="shortDescription"
              id="shortDescription"
              value={shortJobDescription}
              onChange={(e) => {
                e.target.value.length === 0
                  ? setShortJobDescriptionValidate(true)
                  : setShortJobDescriptionValidate(false);
                setShortJobDescription(e.target.value);
              }}
              placeholder="Enter short job description"
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div
            className={`step__item ${
              openingsRangeValidation || openingsValidate ? "error" : ""
            }`}
          >
            <p className="step__item__label">
              No of Openings<span> *</span>
            </p>
            <input
              type="number"
              value={openings.value}
              className="step__item__input"
              max={50}
              min={0}
              onChange={(e: any) => {
                setOpening({ value: e.target.value, label: e.target.value });
                if (+e.target.value > 0 && +e.target.value <= 50) {
                  setOpeningsRangeValidation(VALIDATION_MESSAGES.range);
                } else if (e.target.value && e.target.value.includes(".")) {
                  setOpeningsRangeValidation(VALIDATION_MESSAGES.floatValue);
                }
                setOpeningsValidate(false);
              }}
            />
            <p className="error-message">
              {openingsRangeValidation && openingsRangeValidation}
            </p>
          </div>
          <div className={`step__item ${jobTypeValidate ? "error" : ""} `}>
            <p className="step__item__label">
              Job Type<span> *</span>
            </p>
            <ReactSelect
              options={JobTypeOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setJobType({
                  value: currentValue.value,
                  label: currentValue.label,
                });
                setJobTypeValidate(false);
              }}
              value={
                JobTypeOption.find((x) => x.label === jobType.label)
                  ? JobTypeOption.find((x) => x.label === jobType.label)
                  : ""
              }
              placeholder={`Select job type`}
              styles={selectCustomStyle}
              id="jobType"
              instanceId="jobType"
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div
            className={`step__item ${preferableShiftValidate ? "error" : ""} `}
          >
            <p className="step__item__label">
              Preferable Shift<span> *</span>
            </p>
            <ReactSelect
              options={PreferableShiftOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setPreferableShift({
                  value: currentValue.value,
                  label: currentValue.label,
                });
                setPreferableShiftValidate(false);
              }}
              value={
                PreferableShiftOption.find(
                  (x) => x.label === preferableShift.label
                )
                  ? PreferableShiftOption.find(
                      (x) => x.label === preferableShift.label
                    )
                  : ""
              }
              placeholder={`Select preferable shift`}
              styles={selectCustomStyle}
              id="shiftPref"
              instanceId="shiftPref"
            />
            <p className="error-message">Required field</p>
          </div>
          <div className={`step__item ${positionValidate ? "error" : ""} `}>
            <p className="step__item__label">
              Position<span> *</span>
            </p>
            <FilterWithLimitComponent
              filter={{
                value: position,
                onChangeValue: (option: any) => {
                  setPosition(option);
                  option.label?.length === 0
                    ? setPositionValidate(true)
                    : setPositionValidate(false);
                },
              }}
              placeHolder="Select a Position"
              isMulti={false}
              setLimitSearchfunction={selectSearchFuncPositionLimit}
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div className={`step__item ${industryTypeValidate ? "error" : ""} `}>
            <p className="step__item__label">
              Industry Type<span> *</span>
            </p>
            <AsyncSelect
              cacheOptions
              loadOptions={(inputValue) =>
                inputValue.length > 0
                  ? selectSearchFuncIndustry(inputValue)
                  : selectSearchFuncIndustry("")
              }
              defaultOptions
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              value={industryType}
              placeholder={`Select industry type`}
              onChange={(option: any) => {
                setIndustryType(option);
                option.label?.length === 0
                  ? setIndustryTypeValidate(true)
                  : setIndustryTypeValidate(false);
              }}
              id="industry"
              instanceId="industry"
              styles={selectCustomStyle}
            />
            <p className="error-message">Required field</p>
          </div>
          <div
            className={`step__item ${functionalAreaValidate ? "error" : ""} `}
          >
            <p className="step__item__label">
              Functional Area<span> *</span>
            </p>
            <ReactSelect
              options={FunctionalAreaOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setFunctionalArea({
                  value: currentValue.value,
                  label: currentValue.label,
                });
                setFunctionalAreaValidate(false);
              }}
              value={
                FunctionalAreaOption.find((x) => x.label === functionalArea.label)
                  ? FunctionalAreaOption.find(
                      (x) => x.label === functionalArea.label
                    )
                  : ""
              }
              placeholder={`Select functional area`}
              styles={selectCustomStyle}
              id="functionalArea"
              instanceId="functionalArea"
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__row">
          <div className={`step__item ${noticePeriodValidate ? "error" : ""} `}>
            <p className="step__item__label">
              Notice Period<span> *</span>
            </p>
            <ReactSelect
              options={NoticePeriodOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(currentValue: any) => {
                setNoticePeriod({
                  value: currentValue.value,
                  label: currentValue.label,
                });
                setNoticePeriodValidate(false);
              }}
              value={
                NoticePeriodOption.find((x) => x.label === noticePeriod.label)
                  ? NoticePeriodOption.find(
                      (x) => x.label === noticePeriod.label
                    )
                  : ""
              }
              placeholder={`Select notice period`}
              styles={selectCustomStyle}
              id="noticePeriod"
              instanceId="noticePeriod"
            />
            <p className="error-message">Required field</p>
          </div>
        </div>
        <div className="step__bottom">
          {" "}
          {stepValidate && <p className="error-message">{stepValidate}</p>}
        </div>
      </div>
      <div className="step__bottom">
        <button
          className="step__bottom__next button--empty"
          onClick={moveToNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default memo(JobDetails);
