import ChatBotImg from '../../../../image/icon/chatbot.svg'

const BotAvatar = (props) => {
  return (
    <div className={`chatbot-avatar ${props?.className || ''}`}>
    <img src={ChatBotImg} alt='chatbot image'
    {
      ...props
    }
    />
    </div>
  )
}

export default BotAvatar