import { Chatbot } from 'react-chatbot-kit';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import 'react-chatbot-kit/build/main.css';
import BotAvatar from './CutomComponents/BotAvatar';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../../../hook/useTypedSelector';
const JobCreationChatbot = () => {
  const { state } = config;
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const {isLoading} = useTypedSelector(state=>state.chatbotJobsDetails);
  const validateInput = (input) =>{
    if(isLoading){
      return false;
    }
    return input.length > 0 && input.trim().length !=0;
   }

// Load messages from sessionStorage when chatbot opens
useEffect(() => {
  if (isChatbotOpen) {
    const savedMessages = sessionStorage.getItem('chatbot_messages');
    if (savedMessages) {
      try {
        const parsedMessages = JSON.parse(savedMessages);
        if (Array.isArray(parsedMessages)) {
          setMessages(parsedMessages);
        }
      } catch (error) {
        console.error("Failed to parse chatbot messages:", error);
      }
    }
  }
}, [isChatbotOpen]);

// Save messages to sessionStorage
const onSaveMessages = (newMessages) => {
  if (Array.isArray(newMessages)) {
    setMessages(newMessages);
    sessionStorage.setItem('chatbot_messages', JSON.stringify(newMessages));
  }
};

  return (
    <div className={`job-creation-bot ${isChatbotOpen ? 'show' : ''}`}>
        <Chatbot
          key={state.sessionId}
          validator={validateInput}
          config={config}
          actionProvider={ActionProvider}
          messageParser={(props) => <MessageParser {...props} state={state} />}
          headerText='Your Job Agent'
          saveMessages={onSaveMessages}
          messageHistory={messages.length > 0 ? messages : undefined}
        />
      <BotAvatar onClick={() => setIsChatbotOpen(!isChatbotOpen)} className='widget-button' />
    </div>
  );
};

export default JobCreationChatbot;