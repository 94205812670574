import CustomRange from "../CustomRange";
import { NumericFormat } from "react-number-format";

const SalaryRange = ({salary,setSalary}) => {
    const {min,max,value,prevData,minError,maxError}=salary
  return (
    <div className="step__range">
    <div className="step__range__head">
      <div className="step__range__left">
        <div
          className={`step__range__inner  ${minError ? "error" : ""
            }`}
        >
          <span className="step__range__currency">$</span>
          <NumericFormat
            value={prevData}
            onValueChange={(values) => {
                setSalary(prev=>({...prev, prevData: values.floatValue}))
            }}
            className="step__range__input"
            thousandSeparator=","
          />
        </div>
      </div>
      <div className="step__range__right">
        <div
          className={`step__range__inner  ${maxError ? "error" : ""
            }`}
        >
          <span className="step__range__currency">$</span>
          <NumericFormat
            value={value}
            onValueChange={(values) => {
                setSalary(prev=>({...prev, value: values.floatValue}))
            }}
            className="step__range__input"
            thousandSeparator=","
          />
        </div>
      </div>
    </div>
    <div className="step__range__body">
      <CustomRange
        values={[prevData,value]}
        setValues={(values) => {
            setSalary(prev=>({...prev, prevData: values[0], value: values[1]}))
        }}
        maxValue={max}
        minValue={min}
      />
    </div>
  </div>
  )
}

export default SalaryRange