import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../../hook/useTypedSelector";
import { store } from "../../../../store";
import { setChatSavedAnswers } from "../../../../store/reducers/chatJobReducer";

const Buttons = (props) => {
  // disable button once the user clicks on it and show the selected button having class active
  const [selectedButton, setSelectedButton] = useState(null);
  const {savedAnswers} = useTypedSelector(store=>store.chatbotJobsDetails)
  const handleButtonClick = (button:any) => {
    setSelectedButton(button);
    button.onClick && button.onClick();
    store.dispatch(setChatSavedAnswers({[`${props?.payload?.title}`]:JSON.stringify(button)}))
  };

  useEffect(()=>{
    const button = savedAnswers?.[`${props?.payload?.title}`]
    if(button){
      setSelectedButton(JSON.parse(button))
    }
  },[props?.payload?.title,savedAnswers])

  return (
    <div className="chatbot-options">
      {props.payload?.buttons?.length>0 && props.payload?.buttons.map((button:any,index:number)=>(
        <button
        key={index}
        className={`chatbot-options__item ${selectedButton?.label===button.label ? 'active' : ''}`}
        disabled={!!selectedButton}
        onClick={() => handleButtonClick(button)}>
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default Buttons;