import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICandidateWorkflow } from "../../types/redux/candidate-screen";



export const initialStateCandidateScoreboardScreen = {
	name: '',
	avatar: '',
	degree: '',
	experienceYears: '',
	location: '',
	email: '',
	phone: '',
	skills: [],
	position: '',
	cvKey: '',
	cvName: ''
}
export const candidateScoreboard = createSlice({
	name: 'candidate_scoreboard',
	initialState: {
		info:initialStateCandidateScoreboardScreen,
		downloaded:true,
		refetch:false,
		job:{},
		workflows:[],
		schedulePopup:false,
		calendarPopup:{
			open:false,
			startDate:null,
			endDate:null
		},
		updateRoundPopup:{
			currentWorkflow: {},
			open:false,
			id:-1,
			scoreboardId:-1
		}
	},
	reducers: {
		setScheduleMettingPopupScoreboard:(state,action:PayloadAction<boolean>)=>{
			state.schedulePopup=action.payload
		},
		setDataCandidateScoreboardScreen: (state, action: PayloadAction<any>) => {
			state.info=action.payload
		},
		setJobCandidateScoreboardScreen: (state, action: PayloadAction<any>) => {
			state.job=action.payload
		},
		setDownloadedCandidateScoreboardScreen: (state, action: PayloadAction<boolean>) => {
			state.downloaded=action.payload
		},
		setWorkflowsScreenScoreboardProfile: (state, action: PayloadAction<Array<ICandidateWorkflow>>) => {
			state.workflows=action.payload
		},
		setUpdateRoundPopupCandidateScoreboard:(state,action:PayloadAction<any>)=>{
			state.updateRoundPopup=action.payload
		},
		setCalendarPopupCandidateScoreboard:(state,action:PayloadAction<any>)=>{
			state.calendarPopup=action.payload
		},
		setRefetchCandidateScoreboardScreen: (state, action: PayloadAction<boolean>) => {
			state.refetch = action.payload
		}
	}
})
export const {
	setCalendarPopupCandidateScoreboard,
	setDataCandidateScoreboardScreen,
	setDownloadedCandidateScoreboardScreen,
	setWorkflowsScreenScoreboardProfile,
	setUpdateRoundPopupCandidateScoreboard,
	setScheduleMettingPopupScoreboard,
	setRefetchCandidateScoreboardScreen,
	setJobCandidateScoreboardScreen
} = candidateScoreboard.actions

export default candidateScoreboard.reducer