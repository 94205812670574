import { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { selectCustomStyle } from '../../../../styles/selectCustomStyle';
import ReactSelect from 'react-select';
import { useTypedSelector } from '../../../../hook/useTypedSelector';
import { store } from '../../../../store';
import { setChatSavedAnswers } from '../../../../store/reducers/chatJobReducer';

const DynamicOptions = ({ payload ,...props}) => {
    const {
        title='',
        options: initialOptions,
        apiUrl,
        onSelect,
        value: initialValue,
        className = '',
        isMulti = false,
        frozen = false,
    } = payload;
    const {savedAnswers} = useTypedSelector(store=>store.chatbotJobsDetails)
    const [value, setValue] = useState<any>(initialValue || null);
    const [error, setError] = useState<string | null>(null);
    const [hasMore, setHasMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [cachedOptions, setCachedOptions] = useState<any[]>([]);
    const [allOptions, setAllOptions] = useState<any[]>([]);

    const loadOptions = async (inputValue: string) => {
        if (!apiUrl) return [];

        try {
            const pageSize = apiUrl.pageSize || 20;
            const queryParams = new URLSearchParams({
                offset: offset.toString(),
                limit: pageSize.toString(), 
                [`${apiUrl.searchField || 'search'}`]: inputValue
            });

            const response = await fetch(`${apiUrl.url}${apiUrl.isPathParam&&!!inputValue ? `/${inputValue}` : `?${queryParams}`}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch options');
            }

            const data = await response.json();

            const formattedOptions = data?.map((option: any) => {
                return {
                    id: option.id,
                    label: apiUrl.labelField(option),
                    value: apiUrl.valueField(option)
                }
            }) || [];

            if (formattedOptions.length > 0) {
                const newOptions = inputValue ? formattedOptions : [...cachedOptions, ...formattedOptions];
                setAllOptions(newOptions);
                setOffset(inputValue ? 0 : offset + pageSize);
                setCachedOptions(newOptions);
                return newOptions;
            }else{
                return allOptions;
            }
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
            return [];
        }
    };

    const handleMenuScrollToBottom = () => {
        setHasMore(true);
    };

    useEffect(() => {
        async function callLoadOptions(){
            await loadOptions(searchValue);
            setHasMore(false);
        }
        callLoadOptions();
    }, [hasMore, apiUrl]);

    const handleChange = (option: any) => {
        setValue(option);
        onSelect(option);
        store.dispatch(setChatSavedAnswers({[`${title}`]:JSON.stringify(option)}))
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (frozen) {
        return null;
    }
    useEffect(()=>{
        const option = savedAnswers?.[`${title}`]
        if(option){
            setValue(JSON.parse(option))
        }
    },[title,savedAnswers])

    return (
        <div className={`dynamic-options ${className}`}>
            {apiUrl?.url ? (
                <AsyncSelect
                    defaultOptions={allOptions}
                    options={allOptions}
                    value={value}
                    loadOptions={async (inputValue) => {
                        setSearchValue(inputValue);
                        setOffset(0);  
                        setCachedOptions([]); 
                        const data = await loadOptions(inputValue);
                        return data;
                    }}
                    onChange={handleChange}
                    onMenuScrollToBottom={handleMenuScrollToBottom}
                    onBlur={() => {
                        setOffset(0);
                        setCachedOptions([]);
                        loadOptions('');
                    }}
                    styles={selectCustomStyle}
                    isMulti={isMulti}
                    isClearable
                    placeholder="Select"
                    cacheOptions
                    menuPlacement="auto"
                    menuPosition="fixed"
                    isSearchable
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No options found"}
                />
            ) : initialOptions ? (
                <ReactSelect
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={initialOptions||[]}
                    value={value||null}
                    onChange={handleChange}
                    styles={selectCustomStyle}
                    isClearable
                    isMulti={isMulti}
                    placeholder="Select"
                />
            ) : null}
        </div>
    );
};

export default DynamicOptions; 