import { useState } from "react";
import { store } from "../../../store";
import { changeDraftPopup, reset_screen_details } from "../../../store/action-creators";
import axios from "axios";
import { useCurrentPopupData } from "../../../hook/useCurrentPopupData";
import { getEnv } from "@urecruits/api";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import { getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const successImage = require("../../../image/icon/success_image.svg");

const token: string = localStorage.getItem("token");
const {API_RECRUITMENT} = getEnv();


const PublishPopup = () => {
  const location = useParams();
	const data = useCurrentPopupData("draft");
	const actionState = useTypedSelector(state => state.screen.actionState);

	const [success, setSuccess] = useState(false);
	const [errorNotification, setErrorNotification] = useState("");

	const onPublish = () => {
    actionState === 'Edit Job' && (
      axios.patch(`${API_RECRUITMENT}/api/job`, Object.assign(data, {id: parseInt(location.value)}), 
	  getConfig()).then((res) => {
        if (res.status == 200) {
          setSuccess(true);
        }
      })
    )

		actionState === "Create a New Job" && (
			axios.post(`${API_RECRUITMENT}/api/job`, data,getConfig()).then((res) => {
				if (res.status == 201) {
					setSuccess(true);
				}
			})
		);
	};


	return (
		<div className="popup">
			{
				!success ?
					<div className="popup__step">
						<div className="popup__head">
							<p className="popup__head__headline">
								Send to Draft
							</p>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={() => store.dispatch(changeDraftPopup(false))}
								className="popup__head__close"
							>
								<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								      strokeLinecap="round"
								      strokeLinejoin="round"/>
							</svg>
						</div>
						<div className="popup__body">
							<p className="popup__body__text">
								Are you sure you want to send this job to draft? You can come back to this later.
							</p>
						</div>
						<div className="popup__bottom end">
							<button
								className="popup__bottom__cancel button--empty"
								onClick={() => store.dispatch(changeDraftPopup(false))}
							>
								Cancel
							</button>
							<button
								className="popup__bottom__publish button--filled flat"
								onClick={onPublish}
							>
								Send to Draft
							</button>
							{
								errorNotification.length > 0 && (
									<p className="error-message">{errorNotification}</p>
								)
							}
						</div>
					</div>
					:
					<div className="popup__step">
						<div className="popup__head">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="popup__head__close"
								onClick={() => store.dispatch(changeDraftPopup(false))}
							>
								<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
								      strokeLinecap="round"
								      strokeLinejoin="round"/>
							</svg>
						</div>
						<div className="popup__body">
							<img src={successImage} alt="" className="popup__body__image"/>
							<p className="popup__body__headline">
								Successfully Sent to Draft
							</p>
						</div>
						<div className="popup__bottom center">
							<Link
								to={"/recruitment/jobs"}
								className="popup__bottom__publish button--filled"
								onClick={() => {
									store.dispatch(changeDraftPopup(false))
									store.dispatch(reset_screen_details())}
								}
							>
								Got it!
							</Link>
						</div>
					</div>
			}
		</div>
	);
};

export default PublishPopup;