import React from 'react';
  
const MessageParser = ({ children, actions,state }) => {
  const parse = async (message) => {
    const msg = message.toLowerCase();
    if (msg.includes('hello')) {
      return actions.greet();
    }
    else if(msg.includes('reset')|| msg.includes('new chat')||msg.includes('clear')||msg.includes('quit')){
      actions.resetState();
      return actions.greet();
    }
    else{
      actions.normalConversation(msg);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions: actions,
        });
      })}
    </div>
  );
};

export default MessageParser;