import { combineReducers } from 'redux';
import { screenReducer } from "./screenReducer";
import { applyReducer } from "./ApplyReducer";
import chatJobReducer from "./chatJobReducer";


export const rootReducer = combineReducers({
    screen:screenReducer,
    apply:applyReducer,
    chatbotJobsDetails:chatJobReducer
})

export type RootState = ReturnType<typeof rootReducer>


