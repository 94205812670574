import { getEnv } from '@urecruits/api';
import React, { useEffect, useState } from 'react';
import { createClientMessage } from 'react-chatbot-kit';
import { selectCustomStyle } from '../../../styles/selectCustomStyle';
import { store } from '../../../store';
import { changeAbout, changeBENEFITS, changeJobDetails, changeRequirements } from '../../../store/action-creators';
import { useSelector } from 'react-redux';
import { fetchJobSuggestions, formatSalary } from './actionUtils'; // Import the function
import { useCurrentPopupData } from '../../../hook/useCurrentPopupData';
import { setChatAboutCompany, setChatBenefits, setChatJobDetails, setChatRequirements, setChatSavedAnswers, setIsLoading } from '../../../store/reducers/chatJobReducer';
const { API_RECRUITMENT } = getEnv()

const SESSION_KEY = 'chatbot_session';

const ActionProvider = ({ createChatBotMessage, setState, children, state }) => {

  const { jobDetails: jobDetailsState, requirements: requirementsState, aboutCompany: aboutCompanyState, benefits: benefitsState, jobDetailsValidate, requirementsValidate } = useSelector((state: any) => state.screen);
  const chatJobData = useSelector((state: any) => state.chatbotJobsDetails);
  const [saveDetails, setSaveDetails] = useState(false);
  useEffect(() => {
    if (saveDetails) {
      store.dispatch(changeJobDetails(chatJobData.jobDetails))
      store.dispatch(changeRequirements(chatJobData.requirements))
      store.dispatch(changeAbout(chatJobData.aboutCompany))
      store.dispatch(changeBENEFITS(chatJobData.benefits))
    }
  }, [saveDetails, chatJobData])

  useEffect(() => {
    if (state.messages.length > 0) {
      sessionStorage.setItem('chatbot_messages', JSON.stringify(state.messages))
    }
  }, [state?.messages])


  useEffect(() => {
    const defaultFields = {
      jobType: { value: "Full-Time Employees", label: "Full-Time Employees" },
      preferableShift: { value: "General Shift", label: "General Shift" },
      functionalArea: { value: "Information Technology (IT)", label: "Information Technology (IT)" },
      noticePeriod: { value: "1 Month", label: "1 Month" },
      opening: { value: "1", label: "1" },
    }
    store.dispatch(changeJobDetails({
      ...jobDetailsState,
      ...defaultFields,
    }))
    store.dispatch(setChatJobDetails(defaultFields));
  }, [])
  const greet = () => {
    const widgetId = createUniqueWidgetId('select-job');
    const message = createChatBotMessage("Hello there! What job would you like to create?", {
      widget: "select-job",
      payload: {
        title: widgetId,
        savedAnswers: chatJobData.savedAnswers,
        setSavedAnswers: (data) => {
          store.dispatch(setChatSavedAnswers(data))
        }
      },
      delay: 1000,
    })
    setState((state) => ({ ...state, messages: [...state.messages, message] }));
  }

  const resetState = () => {
    setState((state) => ({ ...state, messages: [], jobDetails: {} }));
    store.dispatch(setChatSavedAnswers({}))
  };

  // New function to extract and sync job details
  const extractJobDetails = (jobDetails) => {
    // Extract about company details
    let updatedAboutCompany = {
      publicSearch: true,
      ...(jobDetails.linkedIn && { linkedIn: jobDetails.linkedIn }),
      ...(jobDetails.facebook && { facebook: jobDetails.facebook }),
      ...(jobDetails.instagram && { instagram: jobDetails.instagram }),
      ...(jobDetails.twitter && { twitter: jobDetails.twitter }),
      ...(jobDetails.aboutCompany && { aboutCompany: jobDetails.aboutCompany })
    };

    // Extract benefits - handle nested benefitsList structure
    let updatedBenefits = !!jobDetails.benefits?.length ? jobDetails.benefits?.map(i => ({ id: Date.now(), value: i })) : chatJobData.benefits?.benefitsList || [];

    // Extract job details
    const updatedJobDetails = {
      ...(jobDetails.title && { jobTitle: jobDetails.title }),
      ...(!!jobDetails.salaryMonthMin && { salaryRangeMonth: [jobDetails.salaryMonthMin, jobDetails.salaryMonthMax ?? chatJobData.jobDetails.salaryRangeMonth?.[1] ?? 0] }),
      ...(!!jobDetails.salaryMonthMax && { salaryRangeMonth: [jobDetails.salaryMonthMin ?? chatJobData.jobDetails.salaryRangeMonth?.[0] ?? 0, jobDetails.salaryMonthMax] }),
      ...(!!jobDetails.salaryYearMin && { salaryRangeYear: [jobDetails.salaryYearMin, jobDetails.salaryYearMax ?? chatJobData.jobDetails.salaryRangeYear?.[1] ?? 0] }),
      ...(!!jobDetails.salaryYearMax && { salaryRangeYear: [jobDetails.salaryYearMin ?? chatJobData.jobDetails.salaryRangeYear?.[0] ?? 0, jobDetails.salaryYearMax] }),
      ...(!!jobDetails.salaryHourMin && { salaryRangeHour: [jobDetails.salaryHourMin, jobDetails.salaryHourMax ?? chatJobData.jobDetails.salaryRangeHour?.[1] ?? 0] }),
      ...(!!jobDetails.salaryHourMax && { salaryRangeHour: [jobDetails.salaryHourMin ?? chatJobData.jobDetails.salaryRangeHour?.[0] ?? 0, jobDetails.salaryHourMax] }),
      ...(jobDetails.description && { jobDescription: jobDetails.description }),
      ...(jobDetails.shortDescription && { shortJobDescription: jobDetails.shortDescription }),
      ...(jobDetails.employer && { employer: jobDetails.employer }),
      ...(jobDetails.remoteLocation && { remoteLocation: jobDetails.remoteLocation }),
      ...(jobDetails.position && { position: jobDetails.position }),
      ...(jobDetails.industry && { industryType: jobDetails.industry }),
    };

    // Extract requirements
    let updatedRequirements = {
      ...(!!jobDetails.skills?.length && { skills: jobDetails.skills }),
      ...(!!jobDetails.experienceMin && { experienceYears: [jobDetails.experienceMin, jobDetails.experienceMax ?? chatJobData.requirements.experienceYears?.[1] ?? 1] }),
      ...(!!jobDetails.experienceMax && { experienceYears: [jobDetails.experienceMin ?? chatJobData.requirements.experienceYears?.[0] ?? 1, jobDetails.experienceMax] }),
      ...(jobDetails.education && { education: { value: jobDetails.education, label: jobDetails.education } })
    };

    return {
      updatedJobDetails,
      updatedRequirements,
      updatedAboutCompany,
      updatedBenefits
    };
  };

  const summaryComponent = (updatedJobDetails, updatedRequirements, updatedAboutCompany, updatedBenefits) => (
    <div>
      Based on the job title '{updatedJobDetails.jobTitle || chatJobData.jobDetails.jobTitle}', we suggest the following:
      <p><strong>Salary Ranges:</strong>
        Monthly ${formatSalary(updatedJobDetails.salaryRangeMonth?.[0] ?? chatJobData.jobDetails.salaryRangeMonth?.[0])} - ${formatSalary(updatedJobDetails.salaryRangeMonth?.[1] ?? chatJobData.jobDetails.salaryRangeMonth?.[1])},
        Yearly ${formatSalary(updatedJobDetails.salaryRangeYear?.[0] ?? chatJobData.jobDetails.salaryRangeYear?.[0])} - ${formatSalary(updatedJobDetails.salaryRangeYear?.[1] ?? chatJobData.jobDetails.salaryRangeYear?.[1])},
        Hourly ${updatedJobDetails.salaryRangeHour?.[0] ?? chatJobData.jobDetails.salaryRangeHour?.[0]} - ${updatedJobDetails.salaryRangeHour?.[1] ?? chatJobData.jobDetails.salaryRangeHour?.[1]}</p>
      <p><strong>Experience:</strong> {updatedRequirements.experienceYears?.[0] || chatJobData.requirements.experienceYears?.[0] || 1}-{updatedRequirements.experienceYears?.[1] || chatJobData.requirements.experienceYears?.[1]} years</p>
      <p><strong>Skills:</strong> {updatedRequirements.skills?.length ? updatedRequirements.skills?.join(", ") : chatJobData.requirements.skills?.length ? chatJobData.requirements.skills?.join(", ") : ""}</p>
    </div>
  );

  const formatMessageWithLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (!text.match(urlRegex)) {
      return text;
    }

    // Convert text with URLs to JSX with styled links
    return (
      <span>
        {text.split(urlRegex).map((part, i) => {
          if (part.match(urlRegex)) {
            return (
              <a
                key={i}
                href={part}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: '#0066cc',
                  textDecoration: 'underline',
                  wordBreak: 'break-all'
                }}
              >
                {part}
              </a>
            );
          }
          return part;
        })}
      </span>
    );
  };

  const createUniqueWidgetId = (widgetType) => {
    return `${widgetType}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
  };

  const handleExistingJobTitle = (jobTitle) => {
    const widgetId = createUniqueWidgetId('create-new-position');
    const message = createChatBotMessage(
      `${jobTitle ? jobTitle : "Your entered job"} is already exists. Would you like to create a new position with a different title?`,
      {
        widget: "buttons",
        payload: {
          title: widgetId,
          savedAnswers: chatJobData.savedAnswers,
          setSavedAnswers: (data) => {
            store.dispatch(setChatSavedAnswers(data))
          },
          buttons: [
            {
              label: "Yes, create new position",
              onClick: () => promptJobTitle()
            },
            {
              label: "No, let me modify details",
              onClick: ()=>handleCustomDetails('modify')
            }
          ]
        }
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages.slice(0, -1), message],
    }));
  };

  const handleChatResponse = async (messageStr, showOnlyResponse = false,loadingMessage = "...") => {
    const loadingMsg = createChatBotMessage(loadingMessage, { loading: true, delay: 1000 });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, loadingMsg],
    }));

    try {
      const data = await fetchJobSuggestions(messageStr, state.sessionId);
      
      // Handle job suggestions with formatted details
      const jobDetails = data.formattedJobDetails?.[0];
      const hasValidJobDetails = data.formattedJobDetails?.length > 0 &&
        jobDetails
      const isExisting = jobDetails?.description?.includes("existing") || jobDetails?.description?.includes("exists") || data.chatResponse?.includes("existing") || data.chatResponse?.includes("exists")
      if (hasValidJobDetails && !showOnlyResponse && !isExisting) {
        const { updatedJobDetails, updatedRequirements, updatedAboutCompany, updatedBenefits } = extractJobDetails(jobDetails);

        // Update store with job details
        store.dispatch(setChatJobDetails(updatedJobDetails));
        store.dispatch(setChatRequirements(updatedRequirements));
        store.dispatch(setChatAboutCompany(updatedAboutCompany));
        store.dispatch(setChatBenefits(updatedBenefits));

        // Create confirmation widget with unique ID
        const widgetId = createUniqueWidgetId('confirm-job-details');
        const confirmationMessage = createChatBotMessage("Would you like to use these suggestions?", {
          widget: "buttons",
          payload: {
            title: widgetId,
            savedAnswers: chatJobData.savedAnswers,
            setSavedAnswers: (data) => {
              store.dispatch(setChatSavedAnswers(data))
            },
            delay: 3000,
            buttons: [
              {
                label: "Yes, use these suggestions",
                onClick: () => {
                  showLocationSelection();
                  setSaveDetails(true)
                }
              },
              {
                label: "No, I want to enter custom details",
                onClick: handleCustomDetails
              }
            ]
          }
        });

        // Update chat with summary and confirmation
        setState((prev) => ({
          ...prev,
          messages: [
            ...prev.messages.slice(0, -1),
            createChatBotMessage(summaryComponent(updatedJobDetails, updatedRequirements, updatedAboutCompany, updatedBenefits)),
            confirmationMessage
          ],
        }));
        return;
      }

      // Handle simple chat response
      else if (data.chatResponse || showOnlyResponse) {
        if (isExisting) {
          handleExistingJobTitle(jobDetails?.title)
          return;
        }
        else {
          const formattedResponse = formatMessageWithLinks(data.chatResponse);
          const message = createChatBotMessage(formattedResponse, { delay: 1000 });
          // if(jobDetails){
          //   const { updatedJobDetails, updatedRequirements, updatedAboutCompany, updatedBenefits } = extractJobDetails(jobDetails);
          //   store.dispatch(setChatJobDetails(updatedJobDetails));
          //   store.dispatch(setChatRequirements(updatedRequirements));
          //   store.dispatch(setChatAboutCompany(updatedAboutCompany));
          //   store.dispatch(setChatBenefits(updatedBenefits));
          //   setSaveDetails(true)
          // }
          setState((prev) => ({
            ...prev,
            messages: [...prev.messages.slice(0, -1), message],
          }));
          return;
        }
      }

      // Handle no response case
      const fallbackMessage = createChatBotMessage("I'm sorry, I don't understand that. Please try again.", {});
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages.slice(0, -1), fallbackMessage],
      }));

    } catch (error) {
      console.error("Error in conversation:", error);
      setState((prev) => ({
        ...prev,
        messages: [
          ...prev.messages.slice(0, -1),
          createChatBotMessage("Sorry, I encountered an error. Please try again.")
        ],
      }));
    }
  };

  const normalConversation = async (messageStr) => {
    if(!!messageStr){
      store.dispatch(setIsLoading(true));
      await handleChatResponse(messageStr,true);
      store.dispatch(setIsLoading(false));
    }
  };

  const handleJobSuggestions = async (messageStr) => {
    if(!!messageStr){
      store.dispatch(setIsLoading(true));
      await handleChatResponse(messageStr,false, "Fetching job details, please wait... ⏳");
      store.dispatch(setIsLoading(false));
    }
  };

  const promptJobTitle = () => {
    const widgetId = createUniqueWidgetId('job-title');
    const message = createChatBotMessage("What is the title of the job you want to create?", {
      widget: "job-title",
      payload: {
        title: widgetId,
        savedAnswers: chatJobData.savedAnswers,
        setSavedAnswers: (data) => {
          store.dispatch(setChatSavedAnswers(data))
        }
      }
    });
    setState((state) => ({ ...state, messages: [...state.messages, message] }));
  }
  const sendClientMessage = (messageStr, widgetName, payload) => {
    const message = createClientMessage(messageStr, {
      widget: widgetName,
      payload: payload,
      delay: 1000
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const sendChatMessage = (messageStr, widgetName, payload) => {
    const message = createChatBotMessage(messageStr, {
      widget: widgetName,
      payload: payload,
      delay: 1000,
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleJobCreation = () => {
    const data = useCurrentPopupData("publish");
    console.log(data)
  }

  const showJobCreationConfirmation = () => {
    const message = [
      createChatBotMessage("I have completed filling out the form with the job suggestions."),
      createChatBotMessage("If you need to edit any field, you can do so directly in the form. Anything else i can help you with?", { delay: 3000 }),
    ];

    setState((prev) => ({ ...prev, messages: [...prev.messages, ...message] }));
  }


  const handleLocationSelection = (value: any) => {
    store.dispatch(setChatJobDetails({
      jobLocation: [value]
    }));
    store.dispatch(changeJobDetails({ ...chatJobData.jobDetails, jobLocation: [value] }))
    store.dispatch(setChatJobDetails({ jobLocation: [value] }))
    setTimeout(() => {
      showJobCreationConfirmation()
    }, 2000)
  };

  const showLocationSelection = () => {
    const widgetId = createUniqueWidgetId('location');
    // Then show location selection message
    const locationMessage = createChatBotMessage("Please select the location of the job", {
      widget: "dynamic-options",
      payload: {
        title: widgetId,
        savedAnswers: chatJobData.savedAnswers,
        setSavedAnswers: (data) => {
          store.dispatch(setChatSavedAnswers(data))
        },
        apiUrl: {
          url: `${API_RECRUITMENT}/api/location/filter`,
          labelField: (option: any) => `${option.city}, ${option.state}`,
          valueField: (option: any) => option.id,
          pageSize: 30,
          searchField: "find"
        },
        onSelect: (value: any) => handleLocationSelection(value),
        isMulti: false,
        placeholder: "Select Location",
        customSelectStyle: selectCustomStyle,
      }
    });
    store.dispatch(changeJobDetails({ ...chatJobData.jobDetails }))
    store.dispatch(changeRequirements({ ...chatJobData.requirements }))
    store.dispatch(changeAbout({ ...chatJobData.aboutCompany }))
    store.dispatch(changeBENEFITS({ ...chatJobData.benefits }))

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, locationMessage]
    }));
  };

  const handleCustomDetails = (action='') => {
const msgStr = action == 'modify' ?
'You can modify the job from the jobs list. Anything else i can help you with?'
:
'You can enter the custom details for the job through form. Anything else i can help you with?'
    const message = createChatBotMessage(
      msgStr,
      {}
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message]
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            greet,
            promptJobTitle,
            handleJobSuggestions,
            sendClientMessage,
            sendChatMessage,
            resetState,
            normalConversation
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;