import { Link, useParams } from "react-router-dom";
import { memo, useState } from "react";
import assignIcon from "../../image/icon/assign-to-job_ic.svg";
import { store } from "../../store";
import {
	changeApplyJobStatus,
	changeApplyPopup,
	changeSaveJobStatus,
	changeSubscribeJobStatus,
	changeSuccessfullyPopup,
} from "../../store/apply-action-creators";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { useTypedSelector } from "../../hook/useTypedSelector";
import Loader from "../../../../recruitments/screen/VideoMeetLoader";
import { requestFCMToken } from '../../util/requestNotification';
import { AuthGuard, getConfig } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT} = getEnv();
const JobAction = ({color, applicationForm,jobData}) => {

	const {subscribeStatus, saveStatus, applyStatus} = useTypedSelector(state => state.apply);
	const location = useParams();
	const [displayCopied, setDisplayCopied] = useState(false);
	const [loader, setLoader] = useState(false);

	const onCopyHandler = () => {
		navigator.clipboard.writeText("www.urecruits.com/job/" + location.value);
		setDisplayCopied(true);
		setTimeout(() => {
			setDisplayCopied(false);
		}, 3000);
	};


	const onApplyHandler = () => {
		if (!applicationForm) {
			store.dispatch(changeApplyPopup(true));
		} else {
			setLoader(true)
			axios.post(`${API_RECRUITMENT}/api/subscribe`, {
				applyJob: true,
				jobId:location.value
			}, getConfig()).then((res) => {
				if (res.status === 201) {
					setLoader(false)
					store.dispatch(changeSuccessfullyPopup(true));
					store.dispatch(changeApplyJobStatus(true));
				}
			});
		}
	};

	const onSaveSubscribeHandler = async (jobId: number, saveStatus: boolean, subscribeStatus: boolean) => {
		if(subscribeStatus){
			await requestFCMToken();
		}
		
			const job =await axios.patch(`${API_RECRUITMENT}/api/subscribe/jobs/${jobId}`, {
				saveJob: saveStatus,
				subscribeJob: subscribeStatus,
			},getConfig())
	};

	return (
		<div className={`job-action ${color === "black" ? "black" : ""}`}>
			<AuthGuard module='candidate'>
						<button
							className={`job-action__item button--filled apply-btn ${!applyStatus ? "" : "disabled"}`}
							onClick={onApplyHandler}
						>
							<img src={assignIcon} alt="icon"/>
							{!applyStatus ? "Apply" : "Applied"}
						</button>
						<button
							className={`job-action__item ${saveStatus ? "active" : ""}`}
							onClick={() => {
								onSaveSubscribeHandler(Number(location.value), !saveStatus, subscribeStatus);
								store.dispatch(changeSaveJobStatus(!saveStatus));
							}}
						>
							<SaveSVG color={color}/>
							Save
						</button>
						<button className="job-action__item" onClick={onCopyHandler}>
							<ShareSVG color={color}/>
							Share
							{displayCopied && (
								<p className="job-action__notification">
									Link copied
								</p>
							)}
						</button>
						<button
							className={`job-action__item ${subscribeStatus ? "active" : ""}`}
							onClick={() => {
								onSaveSubscribeHandler(Number(location.value), saveStatus, !subscribeStatus);
								store.dispatch(changeSubscribeJobStatus(!subscribeStatus));
								// !subscribeStatus &&store.dispatch(changeSubscribePopup(true))
							}}
						>
							<SubscribeSVG color={color}/>
							Subscribe
						</button>
						{loader && <Loader/>}
			</AuthGuard>
			<AuthGuard module='recruiter'>
			<>
						<AuthGuard module='job-post' permission='edit'>
							<Link className="job-action__item button--filled" to={`/job/${location.value}/edit`}>
									{jobData?.status == "draft" ? "Publish":"Edit"}
								</Link>
						</AuthGuard>
						{jobData?.status == "publish" && <button className="job-action__item" onClick={onCopyHandler}>
							<ShareSVG color={color}/>
							Share
							{displayCopied && (
								<p className="job-action__notification">
									Link copied
								</p>
							)}
						</button>}
					</>
			</AuthGuard>
		</div>
	);
};

export default memo(JobAction);

const SubscribeSVG = ({color}) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 6.6665C15 5.34042 14.4732 4.06865 13.5355 3.13097C12.5979 2.19329 11.3261 1.6665 10 1.6665C8.67392 1.6665 7.40215 2.19329 6.46447 3.13097C5.52678 4.06865 5 5.34042 5 6.6665C5 12.4998 2.5 14.1665 2.5 14.1665H17.5C17.5 14.1665 15 12.4998 15 6.6665Z"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path
				d="M11.4419 17.5C11.2954 17.7526 11.0851 17.9622 10.8321 18.1079C10.5791 18.2537 10.2922 18.3304 10.0003 18.3304C9.70828 18.3304 9.42142 18.2537 9.16841 18.1079C8.91539 17.9622 8.7051 17.7526 8.55859 17.5"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

const ShareSVG = ({color}) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15 6.66699C16.3807 6.66699 17.5 5.5477 17.5 4.16699C17.5 2.78628 16.3807 1.66699 15 1.66699C13.6193 1.66699 12.5 2.78628 12.5 4.16699C12.5 5.5477 13.6193 6.66699 15 6.66699Z"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round"
				strokeLinejoin="round"/>
			<path
				d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round"
				strokeLinejoin="round"/>
			<path
				d="M15 18.333C16.3807 18.333 17.5 17.2137 17.5 15.833C17.5 14.4523 16.3807 13.333 15 13.333C13.6193 13.333 12.5 14.4523 12.5 15.833C12.5 17.2137 13.6193 18.333 15 18.333Z"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M7.1582 11.2588L12.8499 14.5755" stroke={color === "white" ? "white" : "#464E57"}
			      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12.8415 5.4248L7.1582 8.74147" stroke={color === "white" ? "white" : "#464E57"}
			      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

const SaveSVG = ({color}) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.6663 18.3337L9.99967 13.704L3.33301 18.3337V3.51884C3.33301 3.0277 3.53369 2.55668 3.8909 2.20939C4.24811 1.8621 4.7326 1.66699 5.23777 1.66699H14.7616C15.2668 1.66699 15.7512 1.8621 16.1084 2.20939C16.4657 2.55668 16.6663 3.0277 16.6663 3.51884V18.3337Z"
				stroke={color === "white" ? "white" : "#464E57"} strokeWidth="1.5" strokeLinecap="round"
				strokeLinejoin="round"/>
		</svg>
	);
};
